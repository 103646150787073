<script>
import GoogleMap from '@/views/dashboard/GoogleMap'
import utils from '@/services/utils'

export default {
  name: 'SWFMGoogleMap',
  extends: GoogleMap,
  data () {
    return {
      alarm_zoom_level : 13,
      zoom_level : 14,
    }
  },
  computed: {
    mapConfig () {
      return {
        zoom: 13,
        minZoom: 2,
        //maxZoom: 15,
        center: this.mapCenter,
        mapTypeId: "hybrid",
        //mapId: "9c93a26a7e78b8be"
      }
    }
  },
  methods: {
    buildContents(data) {
      var content = "";
      if (data && Array.isArray(data)) {
        var item = "";
        var type, id;
        //content += '<div style="text-align:center;"><row><img src="http://220.72.137.42:3400/api/flowPhotos/5e33b51575c00000/download/S3-N1448MF01832.jpg" style="width: 370px; height: 200px;">  ';
        //content += '<img src="http://220.72.137.42:3400/api/flowPhotos/5e33b51575c00000/download/KakaoTalk_20220530_131836282_02.jpg" style="width: 370px; height: 200px;"></row></div><br>';
        content += '<thead> <tr>';
        for (var i = 0; i < data.length; i++) {
          type = data[i].type;
          id = data[i].id;
          if(type === 35){
            content +='';
          }
          else{
            item = utils.getSensorTypeName(type, id, false);
            if(type === 9 && id === 1 && data[i].value == 0)
              content +='';
            else if(type === 9 && id === 1 && data[i].value == 1)
              content +='<th class="tg-0lax">연기감지</th>';
            else            
              content += '<th class="tg-0lax">' + item + '</th>';
          }
        }
        content += '</tr></thead>';
        content += '<tbody> <tr>';
        for (var i = 0; i < data.length; i++) {
          type = data[i].type;
          id = data[i].id;
          if(type === 35 || type === 36){
            content +='';
          }
          else{
            if(type === 9 && id === 1 && data[i].value == 0)
              content +='';
            else if(type === 9 && id === 1 && data[i].value == 1)
              content +='<td class="tg-0lax">감지</td>';            
            else
              content += '<td class="tg-0lax">' + data[i].value.toFixed(1) + '</td>';
          }
        }        
        content += '</tr></tbody>';

      }
      return content;
    },
    buildPopupMessage(feature) {
      var self = this
      var url = '#/swfm_device/' + feature.guid;

      return new Promise((resolve, reject) => {
        // var content = '<p><strong>&nbsp;&nbsp;' + feature.name + '</strong></p>';
        var content = '<p style="font-family:arial;font-size: 9pt;"><strong><b>' + feature.name + '</b></strong></p>';
        content += '<table class="tg">';
        if (!_.isEmpty(feature.sensor)) {
          content += self.buildContents(feature.sensor.data);
        }
        content += '</table><br>';

        content += '&nbsp&nbsp<i class="fa fa-info-circle" aria-hidden="true"></i>';
        content += '&nbsp<a href="' + url + '"> 디바이스 상세보기 </a><br>';
        resolve(content);
      });
    },    
    addMouseEvent(marker, feature, infowindow, type) {
      this.infoWindows.forEach(function(w) {
        w.close();
      });
      infowindow.open(this.map, marker);
      if (this.selected_marker != marker) {
        // this.getSensorData(feature.guid, feature.site_guid);
      }
      setTimeout(function() {
        infowindow.close();
      }, '3000');
      this.selected_marker = marker;
      this.selected_device_guid = feature.guid;
    },
    addHeatMapData(info){
      var self = this;
      var value;
      info.forEach(device => {
        value = device.weight <= 0 || _.isUndefined(device.weight) ? 0.01 : device.weight;
        self.heatmapData.push({
              location: new self.google.maps.LatLng(device.latitude, device.longitude),
              weight: value
        });
      })    
      
      if(!this.heatmap){
        this.heatmap = new this.google.maps.visualization.HeatmapLayer({
          data: this.heatmapData,
          radius: 80,
          //map: this.map
        });
      }
      this.heatmap.setMap(this.map);
    },
  }
};
</script>

<style type="text/css">
  .tg  
  {
    margin: auto;
    width: 100%;
    border-collapse:collapse;
    border-color:#ccc;
    border-spacing:0;
  }
  .tg td
  {
    text-align:center;
    background-color:#fff;
    border-color:#ccc;
    border-style:solid;
    border-width:1px;
    color:#333;
    font-family:Arial, sans-serif;
    font-size:14px;
    overflow:hidden;
    padding:10px 5px;
    word-break:normal;
  }
  .tg th
  {
    text-align:center; 
    background-color:#f0f0f0;
    border-color:#ccc;
    border-style:solid;
    border-width:1px;
    color:#333;
    font-family:Arial, sans-serif;
    font-size:14px;
    font-weight:normal;
    overflow:hidden;
    padding:10px 5px;
    word-break:normal;
  }
  .tg .tg-baqh
  {
    text-align:center;
    vertical-align:top
  }
</style>