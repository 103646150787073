<template>
  <div class="containerBox">
    <div class="searchBar">
      <input class="form-control" type="text" placeholder="장치이름을 입력하세요" @input="searchData($event)" />
      <select class="selects" v-model="selected" @change="onClickTab(selected)">
        <option value="">현재 위치 선택 </option>
        <option v-for="(site, index) in siteTables" :value="index" :key="index">{{ site.title }}</option>
      </select> 
    </div>

    <div class="searchResult" >
      <div class="group" v-for="(site, index) in siteTables" :key="index">
        <div class="group-item" id="device-item-box" v-for="(data, index) in site.data" :key="index" :style="{ backgroundColor: getBackgroundColor(data.level) }">
          <div class="title" id="device-title"><router-link :to="`swfm_device/${data.guid}`">{{ data.name }}</router-link>
            <span v-if="data.level == 2" style="margin-left:2.5%;"> <i class="fa fa-warning fa-lg" :style="{ color: getBackgroundColor(data.level) }"></i> </span>
            <span v-else-if="data.level == 3" style="margin-left:2.5%;"> <i class="fa fa-fire fa-lg" :style="{ color: getBackgroundColor(data.level) }"></i> </span>
            <span v-else style="margin-left:2.5%;"><i class="fa fa-check-circle fa-lg" style="color:rgb(67, 160, 71);"></i> </span>
          </div> 
          <!-- <div class="underline"></div> -->
          <div class="iconWrapper" v-if="data.level == 1">
            
            <!-- <div class="rfstatus" v-html="findRFImg(data)"></div> -->
          </div>
          <div class="imgWrapper" id="imgWrapper">
            <div class="img" v-html="findCameraImg(data)"></div>
            <div class="rfstatus" v-html="findRFImg(data)"></div>
            <div class="battery" id="wf-battery" v-html="findBatteryImg(data)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SRDSDevice from "@/views/srds/Device.vue";
import loopback from '@/services/loopback';
import moment from 'moment';
import EventBus from '@/services/EventBus'

import utils from '@/services/utils';
import { IMAGES } from "@/constants/constant"
import CronParser from 'cron-parser'
import {
  DEVICE_TABLE_OPTIOINS, SRDS_DEVICE_TABLE_COLUMS,  LEAF_TYPES
} from "@/constants/deviceSettings";


export default {
  name: 'swfmDevice',
  extends: SRDSDevice,
  data () {
    return {
      active_site_index: 0,
      siteTables: [],
      dashboard: {},
      sensor_type: utils.getSensorTypes(),
      selected: '',
      searchResults: [],
      // deviceContainer: document.getElementById("device-item-box").style.backgroundColor
    }
  },
//   mounted(){
//     this.selected = this.user.active_site_index
//     this.onClickTab(this.active_site_index)
//   },
  methods: {
    findCameraImg(data) {
      let result
      let row = data
      if(data.images === undefined) {
        return `<a href='#/{0}device/{1}'><img src="{2}"style="width:222px; height:138px; border-radius:10px;" border: 2px solid #eee; /></a>`.format(this.prefix, row.guid, '/img/logo3.bd1ef8a4.png') 
      }

      result = this.imageFormatter(row.images, row, row.sensor)
      
      return result
    },
    imageFormatter(value, row, sensor_data) {
      if (_.isUndefined(value) || value.length < 1) {
        return this.buildsensorFormatter(sensor_data, row);
      }

      var color = "0,0,0";
      var text_color = "lightgray";
      var label = '';
      var status = '측정일시';
      sensor_data.data.forEach(function(v) {
        if (!_.get(row, "empty", false)) {
          if (v.id === 0) v.id = 1;
          /*switch(v.type){
            case 9:
              status = "충격감지";
              color = v.value == 1 ? "255,0,0" : "0,0,0" ;
              text_color = "white";
              break;
          }*/
          if (new Date(row.prev_time).getTime() >= new Date(row.sensor_time).getTime()){
              var diffMSec = new Date(row.prev_time).getTime() - new Date(row.sensor_time).getTime();
              var diffDate = diffMSec / (24 * 60 * 60 * 1000);
              if(diffDate >= 1){
                color = "0,0,0";
                status = '미수신 {0}일 경과'.format(Math.floor(diffDate));
                if(diffDate >= 7)
                {
                  status = '점검필요'.format(Math.floor(diffDate));
                  text_color = "orange";
                }
              }
          }

          label = '<div class="sensor_time" style="color:{0}; background-color: rgba({1},.7);"><p>{2}</p>{3}</div>'.format(text_color, color, status, moment(row.sensor_time).format("'YY.MM.DD HH:mm"));
        }
      })
      var images = []
      var device_url = this.getDeviceUrl(row);
      for (var i = 0; i < value.length; i++) {
        var el = value[i]
        if (_.isUndefined(el.storage)) {
          continue
        }
        var url = loopback.defaults.baseURL + '/' + el.storage + 's/' + el.device_guid + '/download/' + el.file_name;
        images.push({
          url: url,
          label: label,
          created_at: el.created_at
        })
      }
      images = _.sortBy(images, ['created_at']).reverse();
      // console.log("//////////////////", images)
      var target_id = 'img_' + row.guid
      var result = '<div id="' + target_id + '" class="carousel slide" data-ride="carousel" data-interval="5000">' +
        '<ol class="carousel-indicators hidden">';
      result += '</ol>'
      var img = '<a href="{0}"><img src="{1}" alt="" style="width: 222px; height: 138px; border-radius: 10px; border: 2px solid #eee; ;" /></a>'
      var caption = '<div class="carousel-caption"><p>{0}</p></div></div>'
      result += img.format(device_url, images[0].url)
      result += caption.format(images[0].label)

      result += '<style scoped>'
      result += '.carousel-indicators {'
      result += '  bottom: -25px;'
      result += '  z-index: 10;'
      result += '}'
      result += '.carousel-inner {'
      result += '  width: 100%;'
      result += '  max-height: 256px !important;'
      result += '  background-color: silver !important;'
      result += '}'
      result += '.carousel-caption {'
      result += '  bottom: -10px;'
      result += '  z-index: 10;'
      result += '  padding: 0px;'
      result += '}'
      result += '</style>'
      // console.log('[Device Carousel html] : ', result)
      return result;
    },
    setTableData() {
      for(let item of this.siteTables) {
        if(item.device_count > 0) {
          this.searchResults = item
        }
      }
    },
    searchData(event) {
      this.setTableData()
      let keyword = event.target.value

      let len = this.searchResults.data.length
      for(let i = 0; i < len; i++) {
        if(
          this.searchResults.data[i].name.includes(keyword) === false &&
          this.searchResults.data[i].site.includes(keyword) === false
        ) {
          document.querySelectorAll('.group-item')[i].style.display = "none"
        } else {
          document.querySelectorAll('.group-item')[i].style.display = 'flex'
        }
      }
    },
    makeTable(site_guids) {
      if (site_guids.length === this.siteTables.length) {
        return;
      }
      this.siteTables = [];
      
      DEVICE_TABLE_OPTIOINS.sortName="level";
      DEVICE_TABLE_OPTIOINS.sortOrder="desc";

      for (var i = 0; i < site_guids.length; i++) {
        var table = {
          options: DEVICE_TABLE_OPTIOINS,
          columns: SRDS_DEVICE_TABLE_COLUMS,
          title: '',
          data: []
        }
        // _.find(table.columns, { field: 'site' }).formatter = this.siteFormatter;
        _.find(table.columns, { field: 'name' }).formatter = this.nameFormatter;
        _.find(table.columns, { field: 'sensor' }).formatter = this.sensorFormatter;
        _.find(table.columns, { field: 'level' }).formatter = this.imageFormatter2;
        _.find(table.columns, { field: 'sensor_time' }).formatter = utils.dateFormatter;
        this.siteTables.push(table)
      }
    },
    getBackgroundColor(value){
      if(value == 1){
        return "#eee"
      }else if(value == 2){
        return "rgb(251, 192, 45)";
      }else if(value == 3){ 
        return "rgb(230, 81, 0)";
        // return "#eee"
      }else return  "#eee"
    },
    levelFormatter(row) {
      var data_s = this.$parent.$refs.eventView.eventTable.data;
      var level = 0;
      var result = 0;
      //console.log(row.guid)
      //console.log(this.$parent.$refs.eventView.eventTable.data[i].device_guid)
      data_s.forEach(function(e){
        if(row.guid == e.device_guid){
          if(e.sensor_type === 32){
            if(e.sensor_id === 1)
              result = e.level;
          }
        }
      });
      level = result;
      return level;
    },
    findRFImg(data) {
      var result = "";
      var status_text = "";
      var imgsrc;
      if (new Date(data.prev_time).getTime() <= new Date(data.sensor_time).getTime()){
        imgsrc = `<i class="fa-solid fa-wifi" style="color:#43a047;"></i>`
        return `<div class="containerBox"><div>${imgsrc}</div><span style ='color: green; font-weight: bold;'>${status_text}</span>`;
      }
      else{
        imgsrc = `<i class="ri-wifi-off-fill" style="color:rgb(230, 81, 0);"></i>`
        return `<div class="containerBox"><div>${imgsrc}</div><span style ='color: rgb(230, 81, 0); font-weight: bold;'>${status_text}</span>`;
      }
    },  
    onClickTab(index) {
      var active_site_index = _.get(this.user, "active_site_index", -1);
      if (active_site_index != index) {
        this.user.active_site_index = index;
        this.user.active_site_name = this.siteTables[index].title;
        utils.setStorage("userInfo", this.userInfo);
      }
      return this.getDeviceList(index)
        .then((siteTable) => {
          let data = {
            site_index : index,
            site : siteTable.title,
            data : siteTable.data[0],
            lat : siteTable.data[0].latitude,
            lng : siteTable.data[0].longitude
          };
          EventBus.$emit("send", data)
          return Promise.resolve(siteTable)
        .then(()=>{
            if (!this.$parent.$refs.eventView) return null;
            var eventTable = this.$parent.$refs.eventView.getTable();
            eventTable.data.forEach((event) => {
               this.$parent.$refs.deviceView.setMarkerImage(event);
            });
          })
        })
        .catch(err => {
          // ignore
        })
    },
    onEvent(event, value, row) {
      var site_guid = event.site;
      var active_site_index = _.get(this.user, "active_site_index", -1);
      var index = _.indexOf(this.user.active_site_guids, site_guid);
      if (active_site_index !== index) {
        $('[href="#site_tab' + index + '"]').tab('show');
        this.onClickTab(index).then(res => {
          if (res != null) this.setMarkerImage(event)
        })
      } else {
        this.setMarkerImage(event)
      }

    },
    onClickRow(field, value, row) {
      this.$parent.$refs.googleMap.setCenter(field)
    },
    setMarkerImage(event) {
      this.$parent.$refs.googleMap.setMarkerImage(event)
    },
    getDeviceList(index, limit, skip) {
      var self = this;
      if (typeof limit === 'undefined') limit = 50
      if (typeof skip === 'undefined') skip = 0

      var default_site_guid = _.get(this.user, "default_site_guid");
      var active_site_index = _.get(this.user, "active_site_index", 0)

      var data = {
        limit: limit,
        skip: skip,
        site_guid: _.isEmpty(this.user.active_site_guids) ? this.user.site_guids : this.user.active_site_guids,
        active_site_index: active_site_index
      }

      // this.makeTable(data.site_guid)
      return loopback
        .method('sites', 'getDeviceList', data)
        .then(res => {
          // console.log('Device::getDeviceList: ', JSON.stringify(res,null,2))
          self.makeTable(_.map(res, 'site_guid'))
          self.user.active_site_guids = []
          res.forEach(function(d, i) {
            var rows = [];
            d.data.forEach(function(e) {
              var interval = CronParser.parseExpression(e.upload_cron)
              var data = {
                site: d.Site.name,
                site_guid: d.Site.guid,
                site_index: i,
                center_device_guid: d.Site.center_device_guid,
                latitude: e.latitude,
                longitude: e.longitude,
                name: e.name,
                service_type: e.service_type,
                address: e.address,
                sensor: e.sensor,
                standard: e.standard,
                empty: e.sensor.empty,
                sensor_time: e.sensor.created_at,
                guid: e.guid,
                threshold: e.threshold,
                images: e.images,
                level: self.levelFormatter(e),
                prev_time: new Date(interval.prev()),
                periodic_pic_active: e.periodic_pic_active
              }
              rows.push(data)
            });
            var active_site_index = _.get(self.user, "active_site_index", -1);
            if (active_site_index != -1 && self.active_site_index != active_site_index) {
              if (active_site_index < res.length)
                self.active_site_index = active_site_index;
            } else {
              if (default_site_guid == d.site_guid) {
                self.active_site_index = i;
              }
            }

            if (_.indexOf(self.user.active_site_guids, d.site_guid) === -1) {
              self.user.active_site_guids.push(d.site_guid);
            }
            self.siteTables[i].title = d.Site.name;
            self.siteTables[i].data = rows;
            self.siteTables[i].device_count = rows.length;
            self.siteTables[i].address = d.Site.address;
          });
          var siteTable = self.siteTables[self.active_site_index]
          self.user.active_site_name = siteTable.title;
          utils.setStorage("userInfo", self.userInfo);
          
          if (!_.isUndefined(self.$parent.$refs.eventView.eventTable)){
            var event_ = self.$parent.$refs.eventView.eventTable;
            if(event_.data.length > 0){
              for(var i = 0; i < event_.data.length; i++){
                for(var j = 0; j < siteTable.device_count; j++){
                  if(siteTable.data[j].service_type == 'swfm'){
                    if(siteTable.data[j].guid == event_.data[i].device_guid){
                      siteTable.data[j].weight = parseFloat(_.isUndefined(event_.data[i].sensor_value.weight)?0.0:event_.data[i].sensor_value.weight);
                    }
                  }
                }
              }
            }
          }

          if (!_.isUndefined(self.$parent.$refs.googleMap))
            self.$parent.$refs.googleMap.update(siteTable);

          //self.$parent.$refs.weatherView.getWeatherData(self.active_site_index, siteTable.address)
          // notify to parent : change
          self.$emit('change', siteTable)
          siteTable.data.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);          
          return Promise.resolve(siteTable)
        })
    }, // getDeviceList
    findBatteryImg(data) {
      var result = "";
      // var status_text = "";
      var imgsrc;
      var thresholds = utils.getThresholds();
      try {
        var level0= _.find(thresholds, {sensor_type : 1, level : 0 }).min;
        var level1= _.find(thresholds, {sensor_type : 1, level : 1 }).min;
        var level2= _.find(thresholds, {sensor_type : 1, level : 2 }).min;
        var level3= _.find(thresholds, {sensor_type : 1, level : 3 }).min;
      } catch(err) {
        return;
      }
      if (_.isUndefined(data.sensor)){
          console.log("isUndefined");
          return;
      }

      data.sensor.data.forEach(function(e){
        if(e.type === 1)
          result = e.value;
      })

      if (result >= level0){ 
        //console.log("level0");
        imgsrc = `<i class="fa fa-battery-full fa-lg" style="color:#43a047;"></i>`
        // status_text = IMAGES.BG.status;
      }
      else if (result >= level1 ){ 
        //console.log("level1");
        imgsrc = `<i class="fa fa-battery-three-quarters fa-lg" style="color:#fbc02d;"></i>`
        // status_text = IMAGES.BN.status;
      }
      else if (result >= level2 ){
        //console.log("level2");                
        imgsrc = `<i class="fa fa-battery-half fa-lg" style="color:#fbc02d;"></i>`
        // status_text = IMAGES.BC.status;
      }
      else if (result >= level3 ){
        //console.log("level3");
        imgsrc = `<i class="fa fa-battery-quarter fa-lg" style="color:#e65100;"></i>`
  
      }
      else {
        //console.log("교체필요");              
        return `<div class="containerBox"><div style="margin-left: 4px;"><i class="fa fa-battery-quarter fa-lg" style="color:#e65100; margin-left: 15px;"></i></div></div>`  /*  <div><span style ='color: #e65100; font-weight: bold;'> 교체필요 </span></div> */
      }
      return `<div class="containerBox"><div style="margin-left: 4px;">${imgsrc}</div></div>` /* <div>${status_text}</div> */
    },
 
  }  
}
  

</script>

<style scoped lang="scss">
.group-item {
  background: #f0f0f099;
  border : 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  width: 262px;
  height: 220px;
  margin-top: 14px;
  margin-bottom: 8px;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 10px;
  transition: box-shadow .3s;
  justify-content: center;
  align-items: center;
}

.searchResult {
  // height: 525px;
  height: 660px;
  overflow: auto;
}

#device-title {
  font-size: 20px;
  font-weight: bold;
  border: 1px solid #eee;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  width: 80%;
  padding: 1.3%;
  
}

#wf-battery{
  position: absolute;
  top: 3px;
  right: 7px;
  z-index: 1; /* 배터리 아이콘을 이미지 위에 표시 */
}

#imgWrapper{
  position: relative;
}

.carousel-caption{
  left: 20%;
}

.rfstatus {
  position: absolute;
  top: 1.8px;
  right: 32px;
  z-index: 1;
}

@media (max-width: 768px) {
  .group-item {
      background: #f0f0f099;
      border : 1px solid #eee;
      display: flex;
      flex-wrap: wrap;
      width: 250px;
      height: 200px;
      margin-top: 14px;
      margin-bottom: 8px;
      margin-right: 15px;
      margin-left: 15px;
      border-radius: 10px;
      transition: box-shadow .3s;
      justify-content: center;
      align-items: center;
    }
}

</style>